import './public-path';
import 'core-js';
import 'zone.js/dist/zone';
import 'reflect-metadata';
import { IntlPolyfill } from 'intl';

if (!(<any>window).Intl) {
    (<any>window).Intl = IntlPolyfill;
}

if ('production' === ENV) {
    // Production

} else {

    // Development
    Error.stackTraceLimit = Infinity;

    /* tslint:disable no-var-requires */
    require('zone.js/dist/long-stack-trace-zone');
}
